/* eslint-disable standard/no-callback-literal */
const testSelect = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}
const testSign = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'blur' }

export default {
  term_condition_check: [
    baseRequirement,
    { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
  ],
  crs_announcement_check: [
    baseRequirement,
    { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
  ],
  acknowledement_check: [
    baseRequirement,
    { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
  ],
  self_signature_key: [
    { validator: testSign, required: true, message: 'Please Sign ', trigger: 'change' }
  ]
}
